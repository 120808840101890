import React from "react"
import Header from "../components/Header"
import CourseSearch from "../components/CourseSearch"
import { Container, Row, Col } from "react-bootstrap"

export default function Home() {
  return (
    <Container fluid="md" >
      <Row>
        <Col>
          <div style={{ }}>
            <Header />
            <CourseSearch />
          </div>
        </Col>
      </Row>
    </Container>
  )
}
